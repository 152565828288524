import { defineStore } from "pinia";

import { z } from "zod";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/notifications";

export const notificationsResponseSchema =
  schema["/dashboard/notifications"].get.response[200].shape.data;
export const notificationsSchema = notificationsResponseSchema.element;

export const useNotificationsStore = defineStore("notifications", {
  state: (): {
    loading: boolean;
    notifications: z.infer<typeof notificationsResponseSchema>;
  } => ({
    loading: false,
    notifications: [],
  }),
  actions: {
    async getNotifications() {
      const response = await this.$client["/dashboard/notifications"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      this.notifications = notificationsResponseSchema.parse(responseJson.data);
    },
  },
});
