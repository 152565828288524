import { defineStore } from "pinia";

import { z } from "zod";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/invitations";

export const putInvitationBodySchema =
  schema["/dashboard/invitations/:action/:nanoid"].put.body;
export const putInvitationParamsSchema =
  schema["/dashboard/invitations/:action/:nanoid"].put.params;
export const invitationsResponseSchema =
  schema["/dashboard/invitations"].get.response[200].shape.data;
export const invitationsSchema = invitationsResponseSchema.element;
export const getInvitationsQueryStringSchema =
  schema["/dashboard/invitations"].get.querystring;

export type InvitationsRole = z.infer<
  typeof invitationsResponseSchema.element.shape.role
>;

export const useInvitationsStore = defineStore("invitations", {
  state: (): {
    loading: boolean;
    notifications: z.infer<typeof invitationsResponseSchema>;
  } => ({
    loading: false,
    notifications: [],
  }),
  actions: {
    async getNotifications(
      payload: z.infer<typeof getInvitationsQueryStringSchema>
    ) {
      const response = await this.$client["/dashboard/invitations"].get({
        query: payload,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      this.notifications = responseJson.data;
    },
    async updateStatus(
      payload: z.infer<typeof putInvitationBodySchema> &
        z.infer<typeof putInvitationParamsSchema> & { index: number }
    ) {
      const response = await this.$client[
        "/dashboard/invitations/{action}/{nanoid}"
      ].put({
        json: {
          status: payload.status,
        },
        params: {
          action: payload.action,
          nanoid: payload.nanoid,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      this.notifications.splice(payload.index, 1);
    },
  },
});
