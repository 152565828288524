import { z } from 'zod'
import { companyNanoid, teamNanoid, userNanoid } from '../../brands.js'
import {
	rise_entities,
	selectable_rise_entities,
} from '../../codegen/zod/rise/rise_entities.js'
import { blockchainAddress, recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const relationshipTypes = selectable_rise_entities.shape.type.exclude([
	'company',
	'team',
	'user',
])

export const schema = {
	'/dashboard/relationships': {
		get: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'List relationships',
			response: {
				200: defaultResponse.extend({
					data: z.array(
						z.object({
							nanoid: z.string(),
							riseid: z.string(),
							rise_account: z.string(),
							name: z.string(),
							type: selectable_rise_entities.shape.type,
							avatar: z.string(),
							parent: z
								.object({
									nanoid: z.string(),
									riseid: z.string(),
									rise_account: z.string(),
									avatar: z.string(),
									type: selectable_rise_entities.shape.type,
									name: z.string(),
								})
								.optional()
								.nullable(),
						}),
					),
				}),
				...defaultErrorResponses,
			},
		},
		delete: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Delete relationships from companies or teams',
			headers: recaptcha,
			body: z.object({
				nanoids: z.array(companyNanoid.or(teamNanoid)),
			}),
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			headers: recaptcha,
			summary: 'Create relationships with companies or teams',
			body: z.object({
				user_nanoid: userNanoid,
				relationships: z
					.object({
						entity_nanoid: companyNanoid.or(teamNanoid),
						type: relationshipTypes,
					})
					.array(),
			}),
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/relationships/workspaces': {
		get: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'List workspaces',
			response: {
				200: defaultResponse.extend({
					data: z.array(
						z.discriminatedUnion('type', [
							z.object({
								avatar: z.string(),
								nanoid: userNanoid,
								type: z.literal('contractor'),
								riseid: blockchainAddress,
								rise_account: blockchainAddress,
							}),
							z.object({
								avatar: z.string(),
								nanoid: teamNanoid.or(companyNanoid),
								team: z.string(),
								company_name: z.string(),
								company_nanoid: companyNanoid,
								type: rise_entities.shape.type.exclude(['contractor']),
								riseid: blockchainAddress,
								rise_account: blockchainAddress,
							}),
						]),
					),
				}),
				...defaultErrorResponses,
			},
		},
	},
} as const satisfies Schema
