import { z } from 'zod'
import { notificationNanoid, userNanoid } from '../../brands.js'
import { recaptcha } from '../../formats.js'
import { notificationData } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const notificationsResponse = defaultResponse.extend({
	data: z.array(
		z.object({
			notification_id: notificationNanoid,
			type: z.string(),
			entity_nanoid: z.string().length(15),
			created_at: z
				.union([z.number(), z.string(), z.date()])
				.pipe(z.coerce.date())
				.nullish(),
			state: z.enum(['unread', 'read']).nullish(),
			data: notificationData,
			text: z.string().nullish(),
		}),
	),
})

const putNotificationBody = z.object({
	status: z.enum(['unread', 'read']),
})

export const schema = {
	'/dashboard/notifications': {
		get: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'List notifications',
			response: {
				200: notificationsResponse,
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/notifications/:action/:nanoid': {
		put: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			headers: recaptcha,
			summary: 'Update notification',
			params: z.object({
				action: z.string().min(1).trim(),
				userNanoid: userNanoid,
			}),
			body: putNotificationBody,
			response: {
				200: defaultResponse,
				...defaultErrorResponses,
			},
		},
	},
} as const satisfies Schema
