import { action_items } from '../../codegen/zod/rise/action_items.js'
import { companyNanoid, teamNanoid } from '../../brands.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'
import { z } from 'zod'

export const schema = {
  '/dashboard/action_items': {
    get: {
      tags: ['Dashboard'],
      security: [{}],
      summary: 'Get all action items',
      querystring: z.object({
        workspace: companyNanoid.or(teamNanoid).optional(),
        status: action_items.shape.status.optional(),
      }),
      response: {
        200: defaultResponse.extend({
          data: z.array(action_items.omit({ idempotency_key: true })),
        }),
        ...defaultErrorResponses
      }
    }
  }
} as const satisfies Schema
