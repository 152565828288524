<script setup lang="ts">
const route = useRoute();

useHead({
  titleTemplate: () => {
    return route.meta.title ? `${route.meta.title} - Rise Pay` : "Rise Pay";
  },
});
</script>

<template>
  <div class="relative min-h-screen w-full bg-dashboard-bg">
    <BackgroundGL class="fixed" />
    <div
      class="absolute sm:top-[60px] top-[20px] sm:left-[60px] left-[20px] flex flex-col gap-2 sm:max-w-[210px]"
    >
      <div>
        <img src="/brand/rise-logo-white.svg" class="sm:h-[60px] h-[50px]" />
      </div>
      <div class="text-white hide-below-768px">
        The easiest and fastest way to send and receive payments
      </div>
    </div>
    <div class="flex items-center justify-center min-h-screen w-screen">
      <slot />
    </div>
  </div>
</template>
