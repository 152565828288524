import { default as _91documentType_93fzeY1gnf9DMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue?macro=true";
import { default as indexVAzE1HbkryMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue?macro=true";
import { default as activity1N33NYvcwQMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue?macro=true";
import { default as addressGpnJqCB61QMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue?macro=true";
import { default as developerbluzPcVKLwMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue?macro=true";
import { default as _91organizationId_93IyQLJrQ1yAMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue?macro=true";
import { default as indexuGbnXFMwdmMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue?macro=true";
import { default as perksTQ6Tr3381zMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue?macro=true";
import { default as profileCZ5r2I2mJIMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue?macro=true";
import { default as referralDHtUpDLLYsMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue?macro=true";
import { default as riseid4h5E2OQRoyMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue?macro=true";
import { default as securityY7dJC2aCgmMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue?macro=true";
import { default as settings8fhv40VtXiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue?macro=true";
import { default as _91uuid_932ILfwuIQY6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_933mOtiLKSnaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as arbitrum_45wallet7HY24xD7C2Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binancevFVLvu3VY6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbaseuTVo0PvE4eMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdwOe5JS9LQTMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletILCYHGS7vRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europeFU35HzaDboMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as forexk5roFBgAXTMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue?macro=true";
import { default as gbpv2I52ToLDsMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultM1S9C90PvNMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usd5b6Xy2iTPMMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakenbnd68fy3VzMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as ngnuqYPCQD9JtMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addfUaSYg6p0kMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue?macro=true";
import { default as indexredzMCArU7Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue?macro=true";
import { default as dashboardhoCZaAlCnFMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue?macro=true";
import { default as completePfsj3rA96OMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingyjsvzySkZnMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue?macro=true";
import { default as _91id_937RM4iUFnfeMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexonuIdIwBTFMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as indexmx9LAnOTbBMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue?macro=true";
import { default as tasksii7mTIV9KlMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue?macro=true";
import { default as index7CUw6bkJGwMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue?macro=true";
import { default as _91nanoid_93LcNuOb1WDMMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue?macro=true";
import { default as indexAAU9HN6je8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue?macro=true";
import { default as cryptoGhIVfntt38Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiath4OrUfYJmIMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as directZvHJvjgZbWMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue?macro=true";
import { default as withdraw8YvM7voYwtMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue?macro=true";
import { default as arbitrum_45walletiCfeiBmJUCMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/arbitrum-wallet.vue?macro=true";
import { default as binance1q7uIAPL77Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/binance.vue?macro=true";
import { default as coinbasegutrQRBPshMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/coinbase.vue?macro=true";
import { default as domestic_45usdgSrYNc3XScMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/domestic-usd.vue?macro=true";
import { default as ethereum_45walletWVrU4O4B6gMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ethereum-wallet.vue?macro=true";
import { default as europepoTjKoDkAGMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/europe.vue?macro=true";
import { default as gbpeR1i2478JcMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/gbp.vue?macro=true";
import { default as international_45usd_45defaultL7K0wGqjWIMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd-default.vue?macro=true";
import { default as international_45usdnDjwqk9vWCMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd.vue?macro=true";
import { default as krakengFl2zEPVuLMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/kraken.vue?macro=true";
import { default as localolRrbaCdxCMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/local.vue?macro=true";
import { default as ngn2hm1B0KHOAMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ngn.vue?macro=true";
import { default as addFmyDLSIJANMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add.vue?macro=true";
import { default as index558UBQezWqMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/index.vue?macro=true";
import { default as benefitsDjDh6KWPp6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue?macro=true";
import { default as dashboard6863mWSf1CMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue?macro=true";
import { default as employee_settingsHbvCwHgb3eMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/employee_settings.vue?macro=true";
import { default as completek2rmu0XwZFMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingjVFlETeaA2Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue?macro=true";
import { default as pay_45slips7BC0ftjvy0Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue?macro=true";
import { default as _91id_93qs0rymKWVhMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue?macro=true";
import { default as indexHpjztAZs7YMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue?macro=true";
import { default as index7nB6mg6Nj3Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue?macro=true";
import { default as reimbursementsCxEmpogPtEMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue?macro=true";
import { default as tasksQE1ALjpkRNMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue?macro=true";
import { default as cryptozdj7Whpvb4Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/crypto.vue?macro=true";
import { default as fiate0RNaMtb5fMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/fiat.vue?macro=true";
import { default as withdrawtR92IUqjjDMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue?macro=true";
import { default as indexpP5y7gaEk8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue?macro=true";
import { default as _91nanoid_93TprT5TgYa2Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue?macro=true";
import { default as jwtf1wvPMIheVMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue?macro=true";
import { default as callbackZ74DMQofH1Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue?macro=true";
import { default as administrators6v5gFk4EmJMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue?macro=true";
import { default as dashboarddNN4S69uTPMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue?macro=true";
import { default as completefhelDnf0gDMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue?macro=true";
import { default as pending1Chu6jf0CqMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue?macro=true";
import { default as directB0dGVaqMvuMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmUx2JLT4yWYMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue?macro=true";
import { default as invitesi0nWzpjGNEMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue?macro=true";
import { default as addressi2mfomnhNMMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue?macro=true";
import { default as configsDzIJUJmaniMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue?macro=true";
import { default as detailswoQDkq3KKLMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue?macro=true";
import { default as ownershipbPb4wD0QY1Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue?macro=true";
import { default as settingsQghqdq6xKYMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue?macro=true";
import { default as subscription4hHDfDyN9KMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue?macro=true";
import { default as taskspk0j9uvLRZMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue?macro=true";
import { default as addjImwshw5TVMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue?macro=true";
import { default as indexnhGli6o8WiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue?macro=true";
import { default as balance_45transfer9XT1EnrLc6Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue?macro=true";
import { default as blockchain_45address4wmufxkse8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue?macro=true";
import { default as wireuLRahvjeIiMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue?macro=true";
import { default as depositJGyfSLVqzaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue?macro=true";
import { default as playgroundIthxdXIkwdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue?macro=true";
import { default as _91_46_46_46id_93oyo1zNPSd5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue?macro=true";
import { default as externalzfb0l7FEe8Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue?macro=true";
import { default as _2faeAh0NXs9qdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue?macro=true";
import { default as code9qsw6C7LGCMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue?macro=true";
import { default as indexM8j8nwKafgMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue?macro=true";
import { default as sign_45outYOOeDrhrWRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue?macro=true";
import { default as codeVJY9pEN7uPMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue?macro=true";
import { default as indexaBiM5Y3sg5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue?macro=true";
import { default as waitingTKd2WqyGrrMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue?macro=true";
import { default as administratorsRg9AeZChq0Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/administrators.vue?macro=true";
import { default as dashboard4col0FVWjhMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue?macro=true";
import { default as dashboardOLDjYqGRNcBGnMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue?macro=true";
import { default as blockchain_45addresswWTCcspM56Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue?macro=true";
import { default as wirerRP9pBU2waMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue?macro=true";
import { default as depositI8i0WNfGBDMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue?macro=true";
import { default as edit1fB4MCKTz4Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue?macro=true";
import { default as completeahUUi2YZm7Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue?macro=true";
import { default as pendingpWCx2ygxvRMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue?macro=true";
import { default as batchaSayFoH7bPMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue?macro=true";
import { default as directrLSFtGghPvMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue?macro=true";
import { default as warmaDA5M5fv7wMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue?macro=true";
import { default as invites1nSHJTMgBjMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue?macro=true";
import { default as benefitsiCChJxcJigMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue?macro=true";
import { default as summaryEDTrLS0655Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue?macro=true";
import { default as _91userId_93IGAEgZ2vMsMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue?macro=true";
import { default as indexfKe9EuJmezMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue?macro=true";
import { default as csviXC7FjlNHEMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue?macro=true";
import { default as indexhhYjDlM5jaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue?macro=true";
import { default as indexhsNgeqYdaOMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue?macro=true";
import { default as directykRmfyj6PdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue?macro=true";
import { default as instantjzBTMMIGWUMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue?macro=true";
import { default as milestone64vdcxkIqcMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue?macro=true";
import { default as oncenXPePqEilkMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue?macro=true";
import { default as recurringQ2vORSSXWqMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue?macro=true";
import { default as _91userId_93JYwPh4w0XaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue?macro=true";
import { default as payableulXmqooTN9Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue?macro=true";
import { default as employeesayzeIECSUlMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/employees.vue?macro=true";
import { default as pausedGpSypkLhvcMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/paused.vue?macro=true";
import { default as pending_actions39KTyEB7HFMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/pending_actions.vue?macro=true";
import { default as currentzcJu1E9oHtMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current.vue?macro=true";
import { default as pay_slipsIzOSJdiLnIMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/pay_slips.vue?macro=true";
import { default as previousBpbtsfR6XbMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/previous.vue?macro=true";
import { default as reportsNtKQcRhRAaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/reports.vue?macro=true";
import { default as settingsv0EbwdQ0mdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/settings.vue?macro=true";
import { default as endedcxkzPX79bBMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/ended.vue?macro=true";
import { default as payrollAMcD6s5QiaMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/payroll.vue?macro=true";
import { default as severanceShswLizLzdMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance.vue?macro=true";
import { default as tax_documentsBJHeWr5qa5Meta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/tax_documents.vue?macro=true";
import { default as payrollniQO6DfrVkMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue?macro=true";
import { default as settingsrRTwS6Lv3GMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue?macro=true";
import { default as tasks3YgTinrAVNMeta } from "/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue?macro=true";
export default [
  {
    name: "scope-workspaceId-documents-documentId-versionId-documentType",
    path: "/:scope()/:workspaceId()/documents/:documentId()/:versionId()/:documentType()",
    meta: _91documentType_93fzeY1gnf9DMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/[documentId]/[versionId]/[documentType].vue").then(m => m.default || m)
  },
  {
    name: "scope-workspaceId-documents",
    path: "/:scope()/:workspaceId()/documents",
    meta: indexVAzE1HbkryMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/[scope]/[workspaceId]/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "account-activity",
    path: "/account/activity",
    meta: activity1N33NYvcwQMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/activity.vue").then(m => m.default || m)
  },
  {
    name: "account-address",
    path: "/account/address",
    meta: addressGpnJqCB61QMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-developer",
    path: "/account/developer",
    meta: developerbluzPcVKLwMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/developer.vue").then(m => m.default || m)
  },
  {
    name: "account-organizations-organizationId",
    path: "/account/organizations/:organizationId()",
    meta: _91organizationId_93IyQLJrQ1yAMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: "account-organizations",
    path: "/account/organizations",
    meta: indexuGbnXFMwdmMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "account-perks",
    path: "/account/perks",
    meta: perksTQ6Tr3381zMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/perks.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileCZ5r2I2mJIMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-referral",
    path: "/account/referral",
    meta: referralDHtUpDLLYsMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-riseid",
    path: "/account/riseid",
    meta: riseid4h5E2OQRoyMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/riseid.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityY7dJC2aCgmMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settings8fhv40VtXiMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add",
    path: "/contractors/:workspaceId()/accounts/add",
    meta: addfUaSYg6p0kMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-forex",
    path: "forex",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/forex.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contractors-workspaceId-accounts",
    path: "/contractors/:workspaceId()/accounts",
    meta: indexredzMCArU7Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-dashboard",
    path: "/contractors/:workspaceId()/dashboard",
    meta: dashboardhoCZaAlCnFMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-complete",
    path: "/contractors/:workspaceId()/history/complete",
    meta: completePfsj3rA96OMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-history-pending",
    path: "/contractors/:workspaceId()/history/pending",
    meta: pendingyjsvzySkZnMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat-id",
    path: "/contractors/:workspaceId()/receivable/flat/:id()",
    meta: _91id_937RM4iUFnfeMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable-flat",
    path: "/contractors/:workspaceId()/receivable/flat",
    meta: indexonuIdIwBTFMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-receivable",
    path: "/contractors/:workspaceId()/receivable",
    meta: indexmx9LAnOTbBMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-tasks",
    path: "/contractors/:workspaceId()/tasks",
    meta: tasksii7mTIV9KlMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries",
    path: "/contractors/:workspaceId()/time_entries",
    meta: index7CUw6bkJGwMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice-nanoid",
    path: "/contractors/:workspaceId()/time_entries/invoice/:nanoid()",
    meta: _91nanoid_93LcNuOb1WDMMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-time_entries-invoice",
    path: "/contractors/:workspaceId()/time_entries/invoice",
    meta: indexAAU9HN6je8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/time_entries/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw",
    path: "/contractors/:workspaceId()/withdraw",
    meta: withdraw8YvM7voYwtMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "contractors-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  },
  {
    name: "contractors-workspaceId-withdraw-nanoId-direct",
    path: ":nanoId()/direct",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/contractors/[workspaceId]/withdraw/[nanoId]/direct.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-accounts-add",
    path: "/employees/:workspaceId()/accounts/add",
    meta: addFmyDLSIJANMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add.vue").then(m => m.default || m),
    children: [
  {
    name: "employees-workspaceId-accounts-add-arbitrum-wallet",
    path: "arbitrum-wallet",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/arbitrum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-binance",
    path: "binance",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/binance.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-domestic-usd",
    path: "domestic-usd",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/domestic-usd.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-ethereum-wallet",
    path: "ethereum-wallet",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ethereum-wallet.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-europe",
    path: "europe",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/europe.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-gbp",
    path: "gbp",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/gbp.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-international-usd-default",
    path: "international-usd-default",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd-default.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-international-usd",
    path: "international-usd",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/international-usd.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-kraken",
    path: "kraken",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/kraken.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-local",
    path: "local",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/local.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-accounts-add-ngn",
    path: "ngn",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/add/ngn.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees-workspaceId-accounts",
    path: "/employees/:workspaceId()/accounts",
    meta: index558UBQezWqMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-benefits",
    path: "/employees/:workspaceId()/benefits",
    meta: benefitsDjDh6KWPp6Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-dashboard",
    path: "/employees/:workspaceId()/dashboard",
    meta: dashboard6863mWSf1CMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-employee_settings",
    path: "/employees/:workspaceId()/employee_settings",
    meta: employee_settingsHbvCwHgb3eMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/employee_settings.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-complete",
    path: "/employees/:workspaceId()/history/complete",
    meta: completek2rmu0XwZFMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-history-pending",
    path: "/employees/:workspaceId()/history/pending",
    meta: pendingjVFlETeaA2Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-pay-slips",
    path: "/employees/:workspaceId()/pay-slips",
    meta: pay_45slips7BC0ftjvy0Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/pay-slips.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat-id",
    path: "/employees/:workspaceId()/receivable/flat/:id()",
    meta: _91id_93qs0rymKWVhMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/[id].vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable-flat",
    path: "/employees/:workspaceId()/receivable/flat",
    meta: indexHpjztAZs7YMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/flat/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-receivable",
    path: "/employees/:workspaceId()/receivable",
    meta: index7nB6mg6Nj3Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/receivable/index.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-reimbursements",
    path: "/employees/:workspaceId()/reimbursements",
    meta: reimbursementsCxEmpogPtEMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/reimbursements.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-tasks",
    path: "/employees/:workspaceId()/tasks",
    meta: tasksQE1ALjpkRNMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw",
    path: "/employees/:workspaceId()/withdraw",
    meta: withdrawtR92IUqjjDMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "employees-workspaceId-withdraw-accountId-crypto",
    path: ":accountId()/crypto",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/crypto.vue").then(m => m.default || m)
  },
  {
    name: "employees-workspaceId-withdraw-accountId-fiat",
    path: ":accountId()/fiat",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/employees/[workspaceId]/withdraw/[accountId]/fiat.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexpP5y7gaEk8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-nanoid",
    path: "/invites/:nanoid()",
    meta: _91nanoid_93TprT5TgYa2Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/invites/[nanoid].vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackZ74DMQofH1Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-administrators",
    path: "/organizations/:workspaceId()/administrators",
    meta: administrators6v5gFk4EmJMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-dashboard",
    path: "/organizations/:workspaceId()/dashboard",
    meta: dashboarddNN4S69uTPMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-complete",
    path: "/organizations/:workspaceId()/history/complete",
    meta: completefhelDnf0gDMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-history-pending",
    path: "/organizations/:workspaceId()/history/pending",
    meta: pending1Chu6jf0CqMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites",
    path: "/organizations/:workspaceId()/invites",
    meta: invitesi0nWzpjGNEMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-settings",
    path: "/organizations/:workspaceId()/settings",
    meta: settingsQghqdq6xKYMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-settings-address",
    path: "address",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-configs",
    path: "configs",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/configs.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-details",
    path: "details",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/details.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-settings-ownership",
    path: "ownership",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/settings/ownership.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organizations-workspaceId-subscription",
    path: "/organizations/:workspaceId()/subscription",
    meta: subscription4hHDfDyN9KMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/subscription.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-tasks",
    path: "/organizations/:workspaceId()/tasks",
    meta: taskspk0j9uvLRZMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/tasks.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams-add",
    path: "/organizations/:workspaceId()/teams/add",
    meta: addjImwshw5TVMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/add.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-teams",
    path: "/organizations/:workspaceId()/teams",
    meta: indexnhGli6o8WiMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-balance-transfer",
    path: "/organizations/:workspaceId()/treasury/balance-transfer",
    meta: balance_45transfer9XT1EnrLc6Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/balance-transfer.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit",
    path: "/organizations/:workspaceId()/treasury/deposit",
    meta: depositJGyfSLVqzaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "organizations-workspaceId-treasury-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45address4wmufxkse8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "organizations-workspaceId-treasury-deposit-wire",
    path: "wire",
    meta: wireuLRahvjeIiMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/organizations/[workspaceId]/treasury/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "receipts-id",
    path: "/receipts/:id(.*)*",
    meta: _91_46_46_46id_93oyo1zNPSd5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/[...id].vue").then(m => m.default || m)
  },
  {
    name: "receipts-external",
    path: "/receipts/external",
    meta: externalzfb0l7FEe8Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/receipts/external.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    meta: _2faeAh0NXs9qdMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    meta: code9qsw6C7LGCMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexM8j8nwKafgMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    meta: codeVJY9pEN7uPMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexaBiM5Y3sg5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingTKd2WqyGrrMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-administrators",
    path: "/teams/:workspaceId()/administrators",
    meta: administratorsRg9AeZChq0Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/administrators.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboard",
    path: "/teams/:workspaceId()/dashboard",
    meta: dashboard4col0FVWjhMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-dashboardOLD",
    path: "/teams/:workspaceId()/dashboardOLD",
    meta: dashboardOLDjYqGRNcBGnMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/dashboardOLD.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit",
    path: "/teams/:workspaceId()/deposit",
    meta: depositI8i0WNfGBDMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-deposit-blockchain-address",
    path: "blockchain-address",
    meta: blockchain_45addresswWTCcspM56Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/blockchain-address.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-deposit-wire",
    path: "wire",
    meta: wirerRP9pBU2waMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/deposit/wire.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-edit",
    path: "/teams/:workspaceId()/edit",
    meta: edit1fB4MCKTz4Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-complete",
    path: "/teams/:workspaceId()/history/complete",
    meta: completeahUUi2YZm7Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/complete.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-history-pending",
    path: "/teams/:workspaceId()/history/pending",
    meta: pendingpWCx2ygxvRMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/history/pending.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites",
    path: "/teams/:workspaceId()/invites",
    meta: invites1nSHJTMgBjMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-invites-batch",
    path: "batch",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/batch.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-direct",
    path: "direct",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-invites-warm",
    path: "warm",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/invites/warm.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members-userId",
    path: "/teams/:workspaceId()/members/:userId()",
    meta: _91userId_93IGAEgZ2vMsMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-members-userId-benefits",
    path: "benefits",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/benefits.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-members-userId-summary",
    path: "summary",
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/[userId]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-members",
    path: "/teams/:workspaceId()/members",
    meta: indexfKe9EuJmezMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch-csv",
    path: "/teams/:workspaceId()/pay/batch/csv",
    meta: csviXC7FjlNHEMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-batch",
    path: "/teams/:workspaceId()/pay/batch",
    meta: indexhhYjDlM5jaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay",
    path: "/teams/:workspaceId()/pay",
    meta: indexhsNgeqYdaOMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId",
    path: "/teams/:workspaceId()/pay/individual/:userId()",
    meta: _91userId_93JYwPh4w0XaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-pay-individual-userId-direct",
    path: "direct",
    meta: directykRmfyj6PdMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/direct.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-instant",
    path: "instant",
    meta: instantjzBTMMIGWUMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-milestone",
    path: "milestone",
    meta: milestone64vdcxkIqcMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-once",
    path: "once",
    meta: oncenXPePqEilkMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-pay-individual-userId-recurring",
    path: "recurring",
    meta: recurringQ2vORSSXWqMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/pay/individual/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payable",
    path: "/teams/:workspaceId()/payable",
    meta: payableulXmqooTN9Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payable.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll",
    path: "/teams/:workspaceId()/payroll",
    meta: payrollniQO6DfrVkMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-current",
    path: "current",
    meta: currentzcJu1E9oHtMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-current-employees",
    path: "employees",
    meta: employeesayzeIECSUlMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/employees.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-current-paused",
    path: "paused",
    meta: pausedGpSypkLhvcMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/paused.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-current-pending_actions",
    path: "pending_actions",
    meta: pending_actions39KTyEB7HFMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/current/pending_actions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll-pay_slips",
    path: "pay_slips",
    meta: pay_slipsIzOSJdiLnIMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/pay_slips.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-previous",
    path: "previous",
    meta: previousBpbtsfR6XbMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/previous.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-reports",
    path: "reports",
    meta: reportsNtKQcRhRAaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/reports.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-settings",
    path: "settings",
    meta: settingsv0EbwdQ0mdMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-severance",
    path: "severance",
    meta: severanceShswLizLzdMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance.vue").then(m => m.default || m),
    children: [
  {
    name: "teams-workspaceId-payroll-severance-ended",
    path: "ended",
    meta: endedcxkzPX79bBMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/ended.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-payroll-severance-payroll",
    path: "payroll",
    meta: payrollAMcD6s5QiaMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/severance/payroll.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-payroll-tax_documents",
    path: "tax_documents",
    meta: tax_documentsBJHeWr5qa5Meta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/payroll/tax_documents.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams-workspaceId-settings",
    path: "/teams/:workspaceId()/settings",
    meta: settingsrRTwS6Lv3GMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/settings.vue").then(m => m.default || m)
  },
  {
    name: "teams-workspaceId-tasks",
    path: "/teams/:workspaceId()/tasks",
    meta: tasks3YgTinrAVNMeta || {},
    component: () => import("/data/runners/ten/_work/apps-monorepo/apps-monorepo/apps/app/pages/teams/[workspaceId]/tasks.vue").then(m => m.default || m)
  }
]